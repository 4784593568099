import React from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';

const Section = styled.section`
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-family: Lato Regular;
  font-size: 32px;
  color: ${$.color.gray1};
  text-align: center;
  margin-bottom: ${$.layout().margin4}px;
`;

const Description = styled.div`
  margin-bottom: ${$.layout().margin4}px;

  & > p:not(:last-child) {
    margin-bottom: ${$.layout().margin2}px;
  }
`;

const StyledIFrameContainer = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const PromotionVideo = () => (
  <Section>
    <Container>
      <Title>Who are we?</Title>
      <Description>
        <p>
          The company&apos;s expertise and industry experience dates back to
          1985 and has been a respectable market leader since then. Our company
          is equipped with modern machineries and a constantly-revised and
          updated set of technology and processes – enabling us to produce high
          quality products to meet the needs of today’s complex and dynamic
          business requirements.
        </p>
        <p>
          We are constantly engaged in research and development to improve the
          quality of our products – all of our manufactured products are of
          Japanese Industrial Standards - JIS A5309 (2010-03-23) which require
          strict quality control and testing.
        </p>
      </Description>
      <StyledIFrameContainer>
        <StyledIFrame
          src="https://www.youtube-nocookie.com/embed/uUoalr2ayEQ?modestbranding=1&controls=0&autohide=1"
          title="Zenn HRB Sdn Bhd"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
          allowFullScreen
        />
      </StyledIFrameContainer>
    </Container>
  </Section>
);

export default PromotionVideo;

import React from 'react';
import PropTypes from 'prop-types';

import Seo from '../components/seo';
import MainBanner from '../components/landing/MainBanner';
import PromotionVideo from '../components/landing/PromotionVideo';
import Partners from '../components/landing/Partners';
import OurProducts from '../components/landing/OurProducts';
import OpenPositions from '../components/landing/OpenPositions';

const IndexPage = ({ location }) => (
  <>
    <Seo title="Home | Zenn HRB" location={location} />
    <MainBanner />
    <PromotionVideo />
    <Partners />
    <OurProducts />
    <OpenPositions />
  </>
);

IndexPage.defaultProps = {
  location: {},
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string])
  ),
};

export default IndexPage;

import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import TNB from '../../assets/images/company-logos/tnb.svg';
import TM from '../../assets/images/company-logos/tm.svg';
import Maxis from '../../assets/images/company-logos/maxis.svg';
import Digi from '../../assets/images/company-logos/digi.svg';
import SESB from '../../assets/images/company-logos/sesb.svg';
import JKR from '../../assets/images/company-logos/jkr.svg';
import KTM from '../../assets/images/company-logos/ktm.svg';

const Section = styled.section`
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div``;

const ImageContainer = styled.div``;

const LogoFlowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  justify-items: center;
  grid-row-gap: ${$.layout().margin4}px;

  & > * {
    height: 48px;

    &.digi {
      transform: scale(1.2);
    }
    &.sesb {
      transform: scale(1.2);
    }
    &.allo,
    &.sarawak {
      & * {
        height: 100%;
        width: auto;
      }
    }

    ${$.device.tablet} {
      transition: filter 0.3s ${$.easingFn.standard};
    }

    ${$.device.desktop} {
      transition: filter 0.3s ${$.easingFn.standard};
    }
  }

  ${$.device.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Title = styled.h2`
  font-family: Lato Regular;
  font-size: 32px;
  color: ${$.color.gray1};
  text-align: center;
  margin-bottom: ${$.layout().margin4}px;
`;

const Partners = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <Title>Our Customers</Title>
        <LogoFlowContainer>
          <TNB className="tnb" />
          <TM className="tm" />
          <Maxis className="maxis" />
          <Digi className="digi" />
          <ImageContainer className="allo">
            <StaticImage
              src="../../assets/images/company-logos/allo.png"
              alt=""
            />
          </ImageContainer>
          <SESB className="sesb" />
          <ImageContainer className="sarawak">
            <StaticImage
              src="../../assets/images/company-logos/sarawak.png"
              alt=""
            />
          </ImageContainer>
          <JKR className="jkr" />
          <KTM className="ktm" />
        </LogoFlowContainer>
      </Fade>
    </Container>
  </Section>
);

export default Partners;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';
import ChevronRightBlack from '../../assets/icons/chevron-right-black.svg';
import ChevronRightWhite from '../../assets/icons/chevron-right-white.svg';
import ImageTransition from './ImageTransition';

const Section = styled.section`
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div`
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${$.color.white};

  ${$.device.mobile} {
    width: 100% !important;
    height: 440px;
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const AspectRatioContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  ${$.device.mobile} {
    padding-top: 75%;
  }
`;

const AR2Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${$.device.mobile} {
    align-items: center;
    padding: 0px 10px;
  }
`;

const Title = styled.div`
  font-family: 'Aileron Heavy';
  font-size: 64px;
  text-transform: uppercase;
  padding-left: ${$.layout().padding5}px;
  margin-bottom: ${$.layout().margin2}px;
  line-height: normal !important;

  & > span {
    color: ${$.color.red};
  }

  ${$.device.mobile} {
    font-size: 35px;
    text-align: center;
    margin-bottom: ${$.layout().margin3}px;
    padding: 0px;
  }
`;

const Subtitle = styled.div`
  padding-left: ${$.layout().padding5}px;
  width: 50%;
  margin-bottom: ${$.layout().margin3}px;

  ${$.device.mobile} {
    width: 90%;
    padding: 0px;
    text-align: center;
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const LinksContainer = styled.div`
  padding-left: ${$.layout().padding5}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${$.device.mobile} {
    padding: 0px;
  }
`;

const ProductsLink = styled(Link)`
  padding: ${$.layout().padding2}px ${$.layout().padding4}px;
  border-radius: 999px;
  background-color: ${$.color.white};
  color: ${$.color.gray1};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: ${$.dropShadow.normal};
  transition: box-shadow 0.3s ${$.easingFn.standard};
  margin-right: ${$.layout().margin3}px;

  &:hover {
    box-shadow: ${$.dropShadow.repressed};
  }

  & > svg {
    width: 15px;
    margin-left: ${$.layout().margin2}px;
    position: relative;
    top: 1px;
  }

  ${$.device.mobile} {
    padding: 12px ${$.layout().padding3}px;
    font-size: 15px;
    line-height: 1em;
  }
`;

const ContactUsLink = styled(Link)`
  text-decoration: none;
  color: ${$.color.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > svg {
    position: relative;
    top: 1px;
    width: 15px;
    margin-left: ${$.layout().margin2}px;
  }

  ${$.device.mobile} {
    line-height: 1em;
  }
`;

const MainBanner = () => (
  <Section>
    <Container>
      <ImageTransition>
        <StaticImage src="../../assets/images/landing/slide-1.jpg" alt="" />
        <StaticImage src="../../assets/images/landing/slide-2.jpg" alt="" />
        <StaticImage src="../../assets/images/landing/slide-3.jpg" alt="" />
      </ImageTransition>
      <AspectRatioContainer>
        <AR2Container>
          <Title>
            Welcome to <br />
            <span>ZENN HRB</span>
          </Title>
          <Subtitle>
            We are the leading manufacturer of prestressed spun concrete poles
            and concrete products with industry experience and a proven track
            record dating back to 1985.
          </Subtitle>
          <LinksContainer>
            <ProductsLink to="/products/">
              Our product <ChevronRightBlack />
            </ProductsLink>
            <ContactUsLink to="/contact-us/">
              Contact us
              <ChevronRightWhite />
            </ContactUsLink>
          </LinksContainer>
        </AR2Container>
      </AspectRatioContainer>
    </Container>
  </Section>
);

export default MainBanner;

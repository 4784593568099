import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const InnerContainer = styled.div`
  ${({ number }) => `
  position: relative;
  width: 100%;
  height: 100%; 

  & > * {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.9s ease-in;
    &:nth-child(${number}) {
      opacity: 1;
    }
  }
`}
`;

const ImageTransition = ({ children }) => {
  const [fade, setFade] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade((number) => {
        if (number >= children.length) {
          return 1;
        }

        return number + 1;
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <InnerContainer number={fade}>{children}</InnerContainer>
    </Container>
  );
};

ImageTransition.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ImageTransition;

import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import ChevronRight from '../../assets/icons/chevron-right-black.svg';

const Section = styled.section`
  height: 630px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

  & > *:first-child {
    object-fit: cover;
    position: absolute;
    top: -50px;
    width: 100% !important;
    z-index: -1;
  }

  ${$.device.tablet} {
    height: 450px;
  }

  ${$.device.mobile} {
    height: 270px;

    & > *:first-child {
      object-fit: cover;
      position: absolute;
      height: 100%;
      top: 0px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.h2`
  color: ${$.color.white};
  font-family: 'Lato Regular';
  font-size: 80px;
  line-height: 1.3em;
  text-align: center;
  margin-bottom: ${$.layout().margin3}px;

  ${$.device.tablet} {
    font-size: 60px;
  }

  ${$.device.mobile} {
    font-size: 30px;
    line-height: 1.2em;

    & > br {
      display: none;
    }
  }
`;

const Button = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${$.color.white};
  padding: ${$.layout().padding2}px ${$.layout().padding4}px;
  border-radius: 999px;
  text-decoration: none;
  color: ${$.color.gray1};

  & > svg {
    position: relative;
    top: 1px;
    margin-left: ${$.layout().margin2}px;
    height: 15px;
    width: auto;
  }

  ${$.device.mobile} {
    padding: ${$.layout().padding1}px ${$.layout().padding3}px;
    line-height: 1.1em;
    font-size: 15px;
  }
`;

const OpenPositions = () => (
  <Section>
    <StaticImage
      src="../../assets/images/landing/open-positions-background.jpg"
      alt=""
    />
    <Container>
      <Title>Explore your career opportunities with us</Title>
      <Button to="/careers/">
        Enquire about open positions <ChevronRight />
      </Button>
    </Container>
  </Section>
);

export default OpenPositions;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import ChevronRight from '../../assets/icons/chevron-right-red.svg';

const Section = styled.section`
  color: ${$.color.gray1};
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div``;

const IntroRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: ${$.layout().margin4}px;

  & > div:last-child {
    flex: 0 0 50%;
  }

  ${$.device.mobile} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const IntroRowCopy = styled.div`
  padding-left: ${$.layout().padding3}px;
  padding-right: ${$.layout().padding3}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  ${$.device.mobile} {
    padding: 0;
  }
`;

const IntroRowImage = styled.div`
  border-left: 4px solid #8c0000;
  border-radius: ${$.border().radius3}px;
  overflow: hidden;

  & > * {
    height: 100%;
  }

  ${$.device.mobile} {
    display: none;
  }
`;

const IntroRowMobileImage = styled.div`
  display: none;

  ${$.device.mobile} {
    display: block;
    height: 350px;
    margin-bottom: ${$.layout().margin3}px;

    & > * {
      height: 100%;
    }
  }
`;

const IntroRowSectionTitle = styled.div`
  color: #8c0000;
  margin-bottom: ${$.layout().margin2}px;
`;

const IntroRowTitle = styled.h2`
  font-family: 'Lato Regular';
  font-size: 32px;
  line-height: 1.2em;
  margin-bottom: ${$.layout().margin3}px;
`;

const IntroRowDescription = styled.p`
  flex: 1;

  ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const IntroRowProductsLink = styled(Link)`
  text-decoration: none;
  color: #8c0000;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    width: 15px;
    position: relative;
    top: 1px;
    margin-left: ${$.layout().margin2}px;
  }

  ${$.device.mobile} {
    align-self: flex-end;
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const ProductsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: ${$.layout().margin4}px;

  & > * {
    width: calc((100% - ${$.layout().margin3}px) / 2);
  }

  ${$.device.mobile} {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & > *:not(:first-child) {
    padding-left: ${$.layout().padding2}px;
    padding-right: ${$.layout().padding2}px;
  }

  ${$.device.mobile} {
    &:not(:last-child) {
      margin-bottom: ${$.layout().margin4}px;
    }
    & > *:last-child {
      padding: 0;
    }
  }
`;

const ConcretePolesCard = styled(ProductCard)``;

const DecorativePolesCard = styled(ProductCard)``;

const ProductImageContainer = styled.div`
  height: 350px;
  overflow: hidden;
  margin-bottom: ${$.layout().margin2}px;

  & > * {
    height: 100%;
  }

  ${$.device.mobile} {
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const ProductTitle = styled.div`
  font-family: 'Lato Regular';
  font-size: 22px;
  margin-bottom: ${$.layout().margin2}px;
  border-left: 3px solid #8c0000;
  padding-left: ${$.layout().padding2 - 3}px !important;
`;

const ProductSubtitle = styled.p`
  margin-bottom: ${$.layout().margin4}px;
  flex: 1;
`;

const ProductRowProductLink = styled(Link)`
  align-self: flex-end;
  text-decoration: none;
  color: #8c0000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    width: 15px;
    position: relative;
    top: 1px;
    margin-left: ${$.layout().margin2}px;
  }
`;

const OurProducts = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <IntroRow>
          <IntroRowCopy>
            <IntroRowSectionTitle>Our Products</IntroRowSectionTitle>
            <IntroRowTitle>Quality assured concrete products</IntroRowTitle>
            <IntroRowMobileImage>
              <StaticImage
                src="../../assets/images/landing/intro-products.jpg"
                alt=""
              />
            </IntroRowMobileImage>
            <IntroRowDescription>
              Our plants are modern and up-to-date, thus providing assurance of
              superior quality concrete products. Strong and maintenance-free,
              the poles are fully accepted and meets the stringent standards set
              by National Electricity Board of Malaysia, Telecoms Malaysia and
              Malaysia Railway.
            </IntroRowDescription>
            <IntroRowProductsLink to="/products/">
              Learn more <ChevronRight />
            </IntroRowProductsLink>
          </IntroRowCopy>
          <IntroRowImage>
            <StaticImage
              src="../../assets/images/landing/intro-products.jpg"
              alt=""
            />
          </IntroRowImage>
        </IntroRow>
      </Fade>
      <Fade bottom distance="100px">
        <ProductsRow>
          <ConcretePolesCard>
            <ProductImageContainer>
              <StaticImage
                src="../../assets/images/landing/prestressed-concrete.jpg"
                alt=""
              />
            </ProductImageContainer>
            <ProductTitle>Prestressed Spun Concrete Poles</ProductTitle>
            <ProductSubtitle>
              Our maintenance-free prestressed spun concrete poles are fully
              accepted and meet the stringent standards set by National
              Electricity Board of Malaysia, Telecoms Malaysia and Malaysia
              Railway.
            </ProductSubtitle>
            <ProductRowProductLink to="/products/#prestressed-spun-concrete-poles">
              Learn more <ChevronRight />
            </ProductRowProductLink>
          </ConcretePolesCard>
          <DecorativePolesCard>
            <ProductImageContainer>
              <StaticImage
                src="../../assets/images/landing/decorative-pole.jpg"
                alt=""
              />
            </ProductImageContainer>
            <ProductTitle>Decorative Poles</ProductTitle>
            <ProductSubtitle>
              Our iconic decorative concrete pole has a unique yet timeless look
              that will make any architectural lighting project stand out.
            </ProductSubtitle>
            <ProductRowProductLink to="/products/#decorative-poles">
              Learn more <ChevronRight />
            </ProductRowProductLink>
          </DecorativePolesCard>
        </ProductsRow>
      </Fade>
      <Fade bottom distance="100px">
        <IntroRow>
          <IntroRowImage>
            <StaticImage
              src="../../assets/images/landing/malaysia-railway-poles.jpg"
              alt=""
            />
          </IntroRowImage>
          <IntroRowCopy>
            <IntroRowMobileImage>
              <StaticImage
                src="../../assets/images/landing/malaysia-railway-poles.jpg"
                alt=""
              />
            </IntroRowMobileImage>
            <ProductTitle>Malaysia Railway Pole</ProductTitle>
            <IntroRowDescription>
              Zenn HRB&apos;s Malaysia Railway poles are built for the railway
              double-track electrification programme. We have designed the poles
              so that they are easy to install and can be easily modified. The
              poles are made of high quality steel and can be installed in any
              rail track in any direction. The poles are also not heavy weight,
              making them suitable for use in all kinds of railway tracks.
            </IntroRowDescription>
            <ProductRowProductLink to="/products/#malaysia-railway-pole">
              Learn more <ChevronRight />
            </ProductRowProductLink>
          </IntroRowCopy>
        </IntroRow>
      </Fade>
    </Container>
  </Section>
);

export default OurProducts;
